// 创建一个名为useResponsive.js的文件
import { onMounted, onUnmounted, ref } from 'vue';


export default function useResponsive(callback) {

  const screen = ref(getScreen())

  const handleResize = () => {
    screen.value = getScreen()
    // 回调方法
    if (typeof callback === 'function') {
      callback(screen)
    }
  };

  onMounted(() => {
    window.addEventListener('resize', handleResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  return {
    screen,
    // 如果需要的话，可以返回一个函数用于触发事件通知其他逻辑
    // emitScreenSizeChange: () => {
    //   // 触发一个自定义事件，这里假设父级提供了某种事件广播机制
    //   // 例如：getCurrentInstance().appContext.config.globalProperties.$emit('screen-size-changed', screenWidth.value);
    // },
  };
}

function getScreen() {
  let screenWidth = document.documentElement.clientWidth
  //   xs	<768px 	
  // sm	≥768px 	
  // md	≥992px 	
  // lg	≥1200px 	
  // xl	≥1920px 
  let rule
  // 判断屏幕大小
  if (screenWidth < 768) {
    rule = 'xs'
  } else if (screenWidth >= 768 && screenWidth < 992) {
    rule = 'md'
  } else if (screenWidth >= 992 && screenWidth < 1200) {
    rule = 'lg'
  } else {
    rule = 'xl'
  }
  return { width: screenWidth, rule }

}