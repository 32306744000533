import { get, post, put, postForm, getDynamicynamic, getFileUseBlobByPost } from './http'

// 博客路由地址
const blogHost = '/blog'

export const getNoteList = p => get(blogHost + '/note/list', p)
export const getNote = p => {
    return get(blogHost + '/note/' + p)
}
export const editNoteById = p => post(blogHost+ '/note/update', p)
export const saveNote = p => post(blogHost + '/note', p)
export const deleteNote = p => post(blogHost + '/note/delete', p)
export const updateNoteInfo = p => post(blogHost + '/note/update-info', p)
export const updateNoteInfoList = p => post(blogHost + '/note/update-info-list', p)


//export const editNoteById = p => getFileUseBlobByPost('demo1/api/export', p)

// 目录
export const getDirList = p => get(blogHost + '/dir/list/' + p)
export const saveDir = p => post(blogHost + '/dir', p)
export const updateDir = p => post(blogHost + '/dir/update', p)
export const updateDirList = p => post(blogHost + '/dir/update-list', p)
export const updateDirAndNoteList = p  =>  post(blogHost + '/dir/update-dir-note', p)
// 文件上传
export const saveFile = p => postForm('/resFile/save', p)

// 用户
export const apiGetUser = p => get('/user/info', p)
// 登录
export const login = p => post('/user/login', p)
// 推出登录
export const logout = p => post('/user/logout', p)
