import { createApp } from 'vue'
import router from './router/index.js'
import ElementPlus from 'element-plus'
import App from './App.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import Footer from './components/Footer.vue';
import NoteDir from './components/blog/NoteDir.vue';



const app = createApp(App).use(router).use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('FooterComponent', Footer);
app.component('NoteDir', NoteDir);
app.mount('#app')