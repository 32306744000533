import { createRouter, createWebHashHistory } from 'vue-router'

// 路由配置
const routes = [
    // {
    //     path: '/',
    //     name: "index",
    //     component: () => import('../components/Index.vue'),

    //     children: [
    //         {
    //             path: '',
    //             name: "hello",
    //             meta: { keepAlive: true },
    //             redirect: "/blog", 
    //             // component: () => import('../components/Hello.vue')
    //         }, {
    //             path: '/blog',
    //             name: "blog",
    //             meta: { keepAlive: true },
    //             component: () => import('../components/blog/Index.vue'),
    //             children: [
    //                 {
    //                     path: 'note/:noteId',
    //                     name: "blogNote",
    //                     meta: { keepAlive: true },
    //                     component: () => import('../components/blog/Note.vue'),
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        path: '/',
        name: "login",
        component: () => import('../components/login/Login.vue'),
        props: route => ({ backBefore: route.query.backBefore })
    },
    {
        path: '/test',
        name: "test",
        component: () => import('../components/blog/ByteEdit.vue')
    }
]

export default createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})