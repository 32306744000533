<!-- 文章的目录  -->

<template>
    <div v-if="pm.screen.value.rule === 'xl'">
        <el-card v-infinite-scroll="loadEmpty" class="infinite-list"
            style="overflow: auto;min-height: calc(100vh - 20vh); height: calc(100vh - 20); max-height: calc(100vh - 10);"
            shadow="never">
            <div style="flex: 1;display: flex;align-items: center;justify-content: space-between;">
                <span>目录</span>
                <el-link @click="nodeAdd(null, null)" :underline="false"> 新增 </el-link>
            </div>
            <el-tree ref="treeRef" :data="nodes" :props="defaultProps" node-key="id" :highlight-current="true"
                :expand-on-click-node="false" @node-click="handleNodeClick" :load="loadNode" lazy draggable
                :allow-drop="allowDrop" @node-drop="handleDrop" @node-drag-end="hadnleDropEnd">
                <template #default="{ node, data }">
                    <div class="custom-tree-node" @mouseenter="showNodeIcon = node.id"
                        @mouseleave="showNodeIcon = null">
                        <span>
                            <el-input v-if="nodeEditCode === node.id" ref="nodeEditInputRef" size="small"
                                @blur="editNodeName(node, data)" @keyup.enter.native="enterEditNodeName($event)"
                                v-model.trim="nodeNameInput" autofocus />
                            <span v-else>
                                <span v-if="data.type === 0">
                                    <el-text tag="b">
                                        <el-icon>
                                            <Notebook />
                                        </el-icon>
                                        {{ node.label }}
                                    </el-text>
                                </span>
                                <span v-else>
                                    <el-text type="info">
                                        {{ node.label }}
                                    </el-text>
                                </span>
                            </span>
                        </span>
                        <span v-if="data.type === 0 && nodeEditCode !== node.id">
                            <a style="margin-left: 8px">
                                <el-icon @click.stop="nodeAdd(node, data)">
                                    <DocumentAdd />
                                </el-icon>
                                <el-icon @click.stop="nodeEdit(node, data)">
                                    <edit />
                                </el-icon>
                            </a>
                        </span>
                        <span v-if="data.type === 1 && node.id === showNodeIcon && nodeEditCode !== node.id">
                            <a style="margin-left: 8px">
                                <el-icon @click.stop="nodeEdit(node, data)">
                                    <edit />
                                </el-icon>
                                <el-icon @click.stop="nodeRemove(node, data)">
                                    <Delete />
                                </el-icon>
                            </a>
                        </span>
                    </div>
                </template>
            </el-tree>
        </el-card>
    </div>
    <div v-else>
        <el-affix :offset="135" style="width: 0;height: 0;">
            <el-button type="primary" @click="drawer = true">
                <el-icon>
                    <Expand />
                </el-icon>
            </el-button>
        </el-affix>
        <el-drawer direction="ltr" v-model="drawer" size="50%">
            <div style="flex: 1;display: flex;align-items: center;justify-content: space-between;">
                <span>目录</span>
                <el-link @click="nodeAdd(null, null)" :underline="false"> 新增 </el-link>
            </div>
            <el-tree ref="treeRef" :data="nodes" :props="defaultProps" node-key="id" :highlight-current="true"
                :expand-on-click-node="false" @node-click="handleNodeClick" :load="loadNode" lazy draggable
                :allow-drop="allowDrop" @node-drop="handleDrop" @node-drag-end="hadnleDropEnd">
                <template #default="{ node, data }">
                    <div class="custom-tree-node" @mouseenter="showNodeIcon = node.id"
                        @mouseleave="showNodeIcon = null">
                        <span>
                            <el-input v-if="nodeEditCode === node.id" ref="nodeEditInputRef" size="small"
                                @blur="editNodeName(node, data)" @keyup.enter.native="enterEditNodeName($event)"
                                v-model.trim="nodeNameInput" autofocus />
                            <span v-else>
                                <span v-if="data.type === 0">
                                    <el-text tag="b">
                                        <el-icon>
                                            <Notebook />
                                        </el-icon>
                                        {{ node.label }}
                                    </el-text>
                                </span>
                                <span v-else>
                                    <el-text type="info">
                                        {{ node.label }}
                                    </el-text>
                                </span>
                            </span>
                        </span>
                        <span v-if="data.type === 0 && nodeEditCode !== node.id">
                            <a style="margin-left: 8px">
                                <el-icon @click.stop="nodeAdd(node, data)">
                                    <DocumentAdd />
                                </el-icon>
                                <el-icon @click.stop="nodeEdit(node, data)">
                                    <edit />
                                </el-icon>
                            </a>
                        </span>
                        <span v-if="data.type === 1 && node.id === showNodeIcon && nodeEditCode !== node.id">
                            <a style="margin-left: 8px">
                                <el-icon @click.stop="nodeEdit(node, data)">
                                    <edit />
                                </el-icon>
                                <el-icon @click.stop="nodeRemove(node, data)">
                                    <Delete />
                                </el-icon>
                            </a>
                        </span>
                    </div>
                </template>
            </el-tree>
        </el-drawer>
    </div>
    <el-dialog v-model="dialogVisible" title="添加文件" width="30%" draggable>
        <el-form ref="formRef" :model="validateForm" class="demo-ruleForm">
            <el-form-item label="类型" prop="type" :rules="[
        { required: true, message: '类型不能为空' },
    ]">
                <el-select v-model="validateForm.type" value-key="id" placeholder="类型">
                    <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item" />
                </el-select>
            </el-form-item>
            <el-form-item label="名称" prop="name" :rules="[
        { required: true, message: '名称不能为空' },
    ]">
                <el-input v-model.number="validateForm.name" type="text" autocomplete="off" />
            </el-form-item>
        </el-form>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="resetForm(formRef)">取消</el-button>
                <el-button type="primary" @click="submitForm(formRef)">
                    添加
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { saveDir, saveNote, deleteNote, getDirList, updateNoteInfo, updateDir, updateDirAndNoteList } from '@/utils/api.js'
import router from '@/router';
import { ElMessage, ElMessageBox } from 'element-plus'
import useResponsive from '@/utils/useResponsive.js'
// import { da } from 'element-plus/es/locale';

const treeRef = ref(null);
const showNodeIcon = ref(null)
const options = ref([
    { id: 1, label: '文章' },
    { id: 2, label: '文件夹' },
])
const drawer = ref(false)
const nodeEditInputRef = ref(null)
const nodeEditCode = ref(null);
const nodeNameInput = ref('')
const formRef = ref({})
const validateForm = ref({
    parentNode: {},
    name: '',
    type: ''
})
const defaultProps = {
    isLeaf: (data, node) => {
        return data.type !== 0
    },
    label: 'name',
    children: 'children',
}
const dialogVisible = ref(false)
function showDialogVisible(b) {
    dialogVisible.value = b
}
const nodes = reactive([])
// 屏幕适应，获取当前屏幕大小
let pm = useResponsive((size) => {
    // 当屏幕发生变化时，会回调该方法

})
// 页面加载完毕后执行
onMounted(() => { })
// 跳转文章详情
function viewNode(noteId) {
    router.push("/blog/note/" + noteId)
}
// 点击node节点
const handleNodeClick = (node) => {
    console.log("handleNodeClick() =>", node)
    if (node.type === 1) {
        viewNode(node.noteId)
    }
}
const loadNode = (node, resolve) => {
    console.log("loadNode() =>", node)
    if (node.level === 0 || node.data.type === 0) {
        let dirId = node.level === 0 ? 0 : node.data.dirId
        getDirList(dirId).then(res => {
            if (res) {
                return resolve(res)
            } else {
                resolve([])
            }
        })
    }
}
const submitForm = async (formEl) => {
    if (!formEl)
        return;
    await formEl.validate((valid, fields) => {
        let form = validateForm.value
        let childNodes = form.parentNode.childNodes
        let first = getLastArrayValue(childNodes)
        let sort = first == null ? 0 : first.data.sort + 1
        if (valid) {
            if (form.type.id === 1) {
                let dirId = form.parentNode.data.dirId
                saveNote({ title: form.name, dirId: dirId, sort }).then(res => {
                    ElMessage({ message: "添加成功" })
                    dialogVisible.value = false
                    treeRef.value.append({ parentId: dirId, type: 1, dirId: null, name: res.title, noteId: res.id, sort }, form.parentNode)
                    viewNode(res.id)
                })
            } else {
                let parentId = form.parentNode.data.dirId
                saveDir({ parentId: parentId, name: form.name, sort }).then(res => {
                    ElMessage({ message: "添加成功" })
                    dialogVisible.value = false
                    treeRef.value.append({ parentId: parentId, type: 0, dirId: res.id, name: res.name, noteId: null, sort }, form.parentNode)
                })
            }
        }
        else {
            console.log('error submit!', fields);
        }
    });
};
const resetForm = (formEl) => {
    if (!formEl)
        return;
    dialogVisible.value = false
    formEl.resetFields();

}
const nodeAdd = (node, data) => {
    node = node ? node : treeRef.value.store.root
    console.log("nodeAdd()", node)
    validateForm.value.parentNode = node
    showDialogVisible(true)
}
const nodeEdit = (node, data) => {
    nodeEditCode.value = node.id
    nodeNameInput.value = data.name
    console.log(this)
}
const editNodeName = (node, data) => {
    if (data.type === 0) {
        updateDir({ id: data.dirId, name: nodeNameInput.value, sort: data.sort, parentId: data.parentId }).then(res => {
            ElMessage({ message: "修改成功" })
            data.name = nodeNameInput.value
        })
    } else {
        updateNoteInfo({ id: data.noteId, title: nodeNameInput.value, sort: data.sort, dirId: data.parentId }).then(res => {
            ElMessage({ message: "修改成功" })
            data.name = nodeNameInput.value
        })
    }
    nodeEditCode.value = -1
}
const enterEditNodeName = (ev) => {
    ev.target.blur()
}
const nodeRemove = (node, data) => {
    console.log("nodeRemove", node, data)
    ElMessageBox.confirm('确定删除吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    })
        .then(() => {
            if (data.type === 1) {
                deleteNote({ id: data.noteId }).then(() => {
                    treeRef.value.remove(node)
                })
            }
        })
        .catch(() => {
        });
}
const loadEmpty = () => {
}
const allowDrop = (draggingNode, dropNode, type) => {
    // draggingNode 当前拖拽的node，dropNode目标dropNode
    // type 参数有三种情况：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
    // console.log(draggingNode, dropNode, type)
    // 判断是否可以拖拽成功
    return (dropNode.data.type === 0 || type !== 'inner')
}

function pushNewSortArray(newN, node, sort, dirId) {
    if (node.data.type === 0) {
        newN.list.push({ type: node.data.type, id: node.data.dirId, sort: sort, parentId: dirId })
    } else {
        newN.list.push({ type: node.data.type, id: node.data.noteId, sort: sort, parentId: dirId })
    }
    newN.nodes.push(node)
}
function getLastArrayValue(arr) {
    if (Array.isArray(arr) && arr.length > 0) {
        return arr[arr.length - 1];
    } else {
        return null;
    }
}
const handleDropEnd = (draggingNode, dropNode, dropType, ev) => {
    console.log("handleDrop")
}
const handleDrop = (draggingNode, dropNode, dropType, ev) => {
    let newN = {
        list: [],
        nodes: []
    }
    let firstSort
    let dirId
    let prevN
    let parentN;
    if (dropType === 'before') {
        parentN = dropNode.parent
        draggingNode = dropNode.previousSibling
        prevN = draggingNode.previousSibling
    } else if (dropType === 'inner') {
        parentN = dropNode
        draggingNode = getLastArrayValue(parentN.childNodes)
        prevN = draggingNode.previousSibling
    } else {
        parentN = dropNode.parent
        draggingNode = dropNode.nextSibling
        prevN = dropNode
    }
    console.log(prevN)
    console.log(parentN)
    if (prevN == null) {
        firstSort = -1
    } else {
        firstSort = prevN.data.sort
    }
    if (parentN.level === 0) {
        dirId = null
    } else {
        dirId = parentN.data.dirId
    }
    pushNewSortArray(newN, draggingNode, ++firstSort, dirId)
    let nextN = draggingNode
    // 原数组
    while (!!(nextN = nextN.nextSibling)) {
        // 判断
        if (nextN.data.sort > firstSort + 1) {
            break
        }
        pushNewSortArray(newN, nextN, ++firstSort, dirId)
    }
    console.log(newN)
    // 修改排序值
    if (newN.list.length > 0) {
        updateDirAndNoteList(newN.list).then(res => {
            // 将新的data重新设置到node中
            for (let i = 0; i < newN.list.length; i++) {
                newN.nodes[i].data.sort = newN.list[i].sort
            }
            ElMessage({ message: "移动成功" })
        })
    }


    // var paramData = [];
    //       // 当拖拽类型不为inner,说明只是同级或者跨级排序，只需要寻找目标节点的父ID，获取其对象以及所有的子节点，并为子节点设置当前对象的ID为父ID即可
    //       // 当拖拽类型为inner，说明拖拽节点成为了目标节点的子节点，只需要获取目标节点对象即可
    //       var data = dropType != "inner" ? dropNode.parent.data : dropNode.data;
    //       var nodeData = dropNode.level == 1 && dropType != "inner" ? data : data.children;
    //       // 设置父ID,当level为1说明在第一级，pid为空
    //       nodeData.forEach(element => {
    //         element.pid = dropNode.level == 1 ? "" : data.id;
    //       });

    //       this.loading = true;
    //       // 得到这次操作需要变更的数据范围，请求后台批量处理即可...
    //       DeptAPI.updateDeptTreeOrder(JSON.stringify(paramData)).then(res => {
    //         console.log(res);
    //         //自行逻辑，可以加提示框message
    //         this.loading = false;
    //       });
};
</script>


<style scoped>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding-right: 8px;
}
</style>

<style>
.el-tree-node__content {
    height: 40px;
}
</style>