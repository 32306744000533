// 引入axios
import axios from 'axios'
// 引入qs模块，对数据进行序列化
//import QS from 'qs'
// 引入message模块，toast提示
// import { message } from 'ant-design-vue'
import { ElMessage } from 'element-plus'
// 引入storage模块，操作token
// import { session } from '@/utils/storage.js'
const baseUrl = 'api'
// 请求超时时间
axios.defaults.timeout = 10000
//axios.defaults.baseURL = "bloghost/";
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    //const token = session.get('Token')
    //token && (config.headers.Authorization = token)
    // 检查是否为 POST 请求且需要发送 multipart/form-data 类型的数据
    if (config.method.toUpperCase() === 'POST' && config.data instanceof FormData) {
      // 如果是 FormData，则自动将 Content-Type 设置为正确的值
      delete config.headers['Content-Type']; // 清除之前可能存在的 json 格式的 Content-Type
    } else if (config.method.toUpperCase() === 'POST') {
      // 其他普通 JSON POST 请求保持原样
      config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    console.log(baseUrl)
    config.url = baseUrl + config.url
    return config
  },
  error => {
    return Promise.error(error)
  })
// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
       // 检查 status code 或者自定义的 code 是否为成功标识
       if (typeof response.data.code !== 'undefined' && response.data.code === '200') { 
        return Promise.resolve(response.data);
      } else {
        ElMessage.error(response.data.message); // 使用 error 类型的消息提示
      }
    } else {
      let msg = response.data.data.message
      if (msg === null || msg === undefined) {
        msg = "请求失败"
      }
      ElMessage.error({ message: msg })
    }
    return Promise.reject(response);
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 500:
          ElMessage({ message: '网错错误，请稍后再试！' })
          break
        case 404:
          ElMessage({ message: '网错错误，请稍后再试！' })
          break
        // 其他错误，直接抛出错误提示
        default:
          ElMessage({ message: error.response.data.message })
      }
      return Promise.reject(error.response)
    }
  }
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        //reject(err.data)
      })
  })
}
/**
* post方法，对应post请求
* @param {String} url [请求的url地址]
* @param {Object} params [请求时携带的参数]
*/
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, JSON.stringify(params), {
      headers: { 'Content-Type': 'application/json' }  //如果写成contentType会报错
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
      })
  })
}
export function postForm(url, form) {
  return new Promise((resolve, reject) => {
    axios.postForm(url, form)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        //reject(err.data)
      })
  })
}
/**
* pust方法，对应post请求
* @param {String} url [请求的url地址]
* @param {Object} params [请求时携带的参数]
*/
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios.put(url, JSON.stringify(params), {
      headers: { 'Content-Type': 'application/json' }  //如果写成contentType会报错
    }
    ).then(res => {
      resolve(res.data)
    }).catch(err => {
      //reject(err.data)
    })
  })
}
/**
 * get方法，对应get请求,直接在地址后面拼串的形式
 * @param {String} url [请求的url地址]
 * @param {String} params [请求时携带的参数]
 */
export function getDynamicynamic(url, params) {
  return new Promise((resolve, reject) => {
    const completeUrl = `${url}/${params}`
    axios.get(completeUrl, {})
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        //reject(err.data)
      })
  })
}
/**
 * post方法，导出文件
 * @param {String} url [请求的url地址]
 * @param {String} params [请求时携带的参数]
 */
export function getFileUseBlobByPost(url, params = {}) {
  return new promise((resolve, reject) => {
    axios({
      method: 'post',
      url,
      data: params,
      responseType: 'blob'
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        //reject(err.data)
      })
  })
}
