<!-- Footer.vue -->

<template>
  <div class="footer">
    <div class="copyright">
      <el-text class="mx-1" type="info">Copyright © 2023 Leon. All Rights Reserved.</el-text>
    </div>
    <div class="beian">
      <el-link href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023024628号-1</el-link>
    </div>
  </div>
</template>

<style scoped>
.footer {
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  background-color: #f5f5f5;
  padding: 1rem;
  text-align: center;
  max-width: 100%;
}

.copyright,
.beian {
  margin-bottom: 0.5rem;
}
</style>